// Colors
$black: #000000;
$white: #ffffff;
$gray-100: #f4f4f4;
$gray-150: #e8e8e8;
$gray-200: #eae8e9;
$gray-250: #c8c7c7;
$gray-300: #acacac;
$gray-400: #dee1e3;
$dark-gray: #686d71;
$dark-blue: #324356;
$blue: #112233;
$red: #ff5f59;
$orange: #f28f08;
$green-100: #00b43740;
$green: #00b437;
$goldColor: gold;
$orangColor: #dc7542;
$blueBlack: #354f5c;
$whiteBlue: #edf2f7;
// Font Sizes
$text-title: 24px;
$text-title2: 22px;
$text-title3: 20px;
$text-title4: 25px;
$text-subtitle: 18px;
$text-headline: 16px;
$text-body: 14px;
$text-caption: 12px;
$text-captio2: 10px;
$text-captio3: 11px;

// IcoMoon
$icomoon-font-family: "SBS" !default;
$icomoon-font-path: "../../assets/fonts/icomoon" !default;

$icx-arrow-right: "\e908";
$icx-bell: "\e909";
$icx-financail-payment: "\e90a";
$icx-comment: "\e90b";
$icx-camera-location: "\e90c";
$icx-edit: "\e90d";
$icx-hotel: "\e90e";
$icx-map: "\e90f";
$icx-itinerary: "\e910";
$icx-moon: "\e911";
$icx-plane: "\e912";
$icx-share: "\e913";
$icx-star: "\e914";
$icx-transport: "\e915";
$icx-trash: "\e916";
$icx-eye-slash: "\e907";
$icx-building: "\e900";
$icx-eye: "\e901";
$icx-images: "\e902";
$icx-shield: "\e905";
$icx-user: "\e906";

// Refactoring ...
$placeholderColor: #acacac;
$goldColor: #112233;
$greyColor: #9c9c9c;
$greyDarkColor: #3c3c3c;
$greyMediumColor: #c6cbd0;
$lightGreyColor: #f9f9f9;
$grey: #656565;
$paragraphColor: $grey;
$whiteColor: #ffffff;
$blueColor: #112233;
$orangColor: #dc7542;
$greenBlackColor: $goldColor;
$borderColor: #e8e8e8;
$greenColor: #009a2f;
$redColor: #dc7542;
$primaryColor: #0070af;
$btnLinkColor: #c8c8c8;
$rateColor: #ffc649;

/* float items*/
$float-left: left;
$float-right: right;
$text-right: right;
$text-left: left;

/* global font size for fast use */
$fontText: 12px;
$font10: 10px;
$font11: 11px;
$font12: 12px;
$font13: 13px;
$font14: 14px;
$font16: 16px;
$font18: 18px;
$font20: 20px;
$font22: 20px;
$font24: 24px;
$font25: 25px;
$font26: 26px;
$font28: 28px;
$font30: 30px;
$font36: 36px;

.text-gray {
	color: $grey !important;
}

.font-8 {
	font-size: 8px;
}
