// Directions
$dir: ltr;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

html {
	--right: right;
	--left: left;
	--dir: ltr;
	--productListTranslate: 150%;
	--percentage: 100%;
}

@import "~bootstrap";

// Base Files
@import "base/variables";
@import "base/reset";
@import "base/functions";
@import "base/mixins";
@import "base/colors";
@import "base/typography";
@import "base/general";
@import "base/custom-modal";

// Shared Components
@import "shared/all";

// Modules
@import "./modules/auth/all";
@import "./modules/labs/all";
@import "./modules/labsAdmin/all";
@import "./modules//companies/all";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&display=swap");

body {
	font-family: "Roboto", sans-serif;
}
button {
	text-transform: capitalize;
}

html[dir="rtl"] {
	--dir: rtl;
	--left: right;
	--right: left;
	--productListTranslate: -150%;
	--percentage: -100%;

	body {
		font-family: "Cairo", sans-serif;
	}
}

.link-company {
	.far,
	.fa,
	.fas {
		font-weight: 600;
		margin: 0 5px;
	}
	font-size: 14px;
	font-weight: 500;
}
.text-gray {
	color: #656565 !important;
}
.is-active {
	background: #112233;
}
.w-100 {
	width: 100%;
}
.fa-2x {
	font-size: 20px !important;
}
.text-fix {
	text-align: left;
	[dir="rtl"] & {
		text-align: right;
	}
}
.pagination {
	direction: ltr;
	[dir="rtl"] & {
		direction: rtl;
	}
}

.min-Height {
	// min-height: 800px;
}

.product-build__product-no-data {
	min-height: 80vh !important;
}

.padding-4 {
	padding-bottom: 4px !important;
	padding-top: 4px !important;
}

.HomeUsersContainer {
	display: flex;
	flex-direction: column;
}

.HomeUsersChar {
	width: 100%;
}

.HomeUsersHeader {
	margin-bottom: 41px;
}

.HomeUsersHeaderTitle {
	font-size: 20px;
	color: #5a5a5a;
	font-weight: bold;
}

.HomeUsersContent {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.HomeUserStatistics {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 60%;
}

.HomeUserStatisticsTitle {
	font-size: 25px;
	color: #5a5a5a;
	font-weight: 400;
}

.HomeUserStatisticsCount {
	font-size: 50px;
	color: #5a5a5a;
	font-weight: bold;
}

.HomeUserCount {
	width: 40%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.HomeUserCountRow {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	margin-bottom: 31px;
	width: 100%;
}

.HomeUserCountIncomingIcon {
	color: #24aefb;
}

.HomeUserCountRunningIcon {
	color: #f28f31;
}

.HomeUserCountCheckedIcon {
	color: #00b437;
}

.HomeUserCountContent {
	display: flex;
	flex-direction: column;
	margin-left: 14px;
}

html[dir="rtl"] .HomeUserCountContent {
	margin-left: unset;
	margin-right: 14px;
}

.HomeUserCountContentTitle {
	color: #2d2f39;
	font-size: 22px;
	font-weight: 600;
}

.HomeUserCountContentNumber {
	color: #2d2f39;
	font-size: 22px;
	font-weight: 600;
}
