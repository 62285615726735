.modification-modal {
	&:first-child {
		border-top: 1px solid #e1e2e3;
	}
	line-height: 18px;

	border-bottom: 1px solid #e1e2e3;
	padding: 0.5rem 0;
}

.route-block {
	a {
		padding: 0.5rem 1rem;
		display: inline-block;
		color: #fff;
		transition: all 0.3s ease-in-out;
		&:hover {
			background: #112233;
		}
	}
}
