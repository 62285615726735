// @import "../helpers/variables";

.mybooking-input-wrapper {
	// change button style
	.btn-primary {
		min-width: 120px !important;
		height: 100%;
		background-color: $goldColor !important;
		outline: none !important;
		border-color: $goldColor;
		border-radius: 0;

		.fa-search {
			font-size: $font22;
		}
	}

	.btn:focus,
	.btn:active {
		outline: none !important;
		box-shadow: none;
	}
	// end button style
	.form-group {
		// margin-bottom: 0px !important;
		position: relative;

		.form-control-custom-input {
			height: 50px !important;
			padding: 15px 40px 0px 10px;
			font-size: $fontText + 3;
			border-left: 4px solid #fff !important;
			border-radius: 5px;
			background: $whiteColor;
		}

		.booking-label {
			position: absolute;
			top: 5px;
			right: 40px;
			font-size: $text-caption;
			color: #d5d5d5;
			margin: 0;
		}

		.fa-calendar {
			@extend %fa-icons-style;
		}

		.fa-suitcase {
			@extend %fa-icons-style;
		}

		.fa-id-card {
			@extend %fa-icons-style;
		}
		i {
			@extend %fa-icons-style;
		}
	}
}

%fa-icons-style {
	position: absolute;
	right: 0;
	top: 35%;
	margin: 0px 5px 0px 5px;
	font-size: $font20;
	color: $grey;
}

.custom-form-group-select {
	margin-bottom: 15px !important;
}

.product-build {
	// Product Collapse
	&__product-collpase {
		// overflow: hidden;
		margin-top: 1rem;
		margin-bottom: 1rem;
		border-radius: 0 0 5px 5px;

		.button-collapse {
			background-color: $gray-100;
			height: 64px;
			display: flex;
			position: relative;
			border: 1px solid #c8c7c7;
			// border-radius: 5px 5px 0 0;
			// z-index: 1;

			label {
				color: #3f4f61;
				text-transform: uppercase;
			}

			.title-style-container {
				display: flex;
				align-items: center;
				width: 100%;
				font-size: $text-caption;
				.right-items-container {
					min-width: 10%;
					width: 98%;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					.img-circle-wrapper {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 50px;
						height: 50px;
						border-radius: 50%;
						line-height: 50px;
						background-color: $blue;
						margin: 0px 10px;
						padding: 20px;
						position: relative;
						z-index: 1;
						span {
							font-size: $text-title2;
							color: #fff;
						}
					}
				}
				.icon-items-section {
					.vertical {
						height: 100%;
						position: absolute;
						top: 0;
						// right: 55px;
						border-left: 1px solid #c8c7c7;
					}
					.fa-trash-alt {
						margin-top: 0px;
						margin-right: 5px;
						margin-left: 5px;
						color: $red;
						font-weight: normal;
					}
					i {
						font-size: $text-title3;
						cursor: pointer;
					}
				}
			}
		}
		.card-body {
			padding: 0.5rem;
		}

		//End Collpase Styling
	}
} //product-build

.product-build__product-collpase {
	.button-collapse {
		.title-style-container {
			.right-items-container {
				width: 96%;
				background: #e9e9e9;
				height: 100%;
				.product-content-items {
					color: #585858;
					font-size: 16px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.img-circle-wrapper {
						background-color: #656565;
						span {
							color: $blueBlack;
						}
					}
				}
			}
			.icon-items-section {
				width: 4%;
				justify-content: center;
				background: #656565;
				height: 100%;
				cursor: pointer;
			}
		}
	}
}
