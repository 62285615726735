.itemBoxCircle {
	width: 21%;
	padding: 0 3px;
	margin-bottom: 15px;
	&:first-child {
		padding-left: 0;
		[dir="rtl"] & {
			padding-right: 0;
			padding-left: 3px;
		}
	}
	.boxCircle {
		display: inline-block;
		width: 100%;
		border: 1px solid #d6d6d6;
		background-color: #f2f2f2;
		box-shadow: 0px 3px 6px #00000029;
		padding: 10px 12px;
		p {
			font-size: $font14;
			color: #656565;
			height: 30px;
			line-height: 30px;
		}
		.counter {
			font-weight: bold;
			float: right;
			font-size: $font22;
		}
		.green {
			color: #63b54d;
		}
		.red {
			color: #d10000;
		}
		.circle {
			font-size: $font10;
			padding: 0 5px;
		}
	}
}
.TeamManagement-header {
	background: #f2f2f2;
	padding: 8px;
	border-bottom: 1px solid #d6d6d6;
	.TeamManagement-title {
		font-size: 20px;
		font-weight: 600;
		color: #656565;
		display: inline-block;
	}
	.TeamManagement-taps {
		float: right;
		[dir="rtl"] & {
			float: left;
		}
		button {
			background-color: #d6d6d6;
			min-width: 220px;
			display: inline-block;
			padding: 5px;
			border: none;
			margin: 0 5px;
			color: #585858;
			border-radius: 4px;
		}
		button.active {
			background-color: #112233;
			color: #ffffff;
		}
	}
}

.Users {
	.row {
		margin: 0 !important;
	}
}
.filter-container {
	overflow: inherit;
	width: 100%;
	display: block;
	.filter-box {
		width: 20%;
		display: inline-block;
		float: left;
		[dir="rtl"] & {
			float: right;
		}
		padding: 0 3px;
	}
	.btn-boxs {
		width: auto;
		display: inline-block;
		margin: 0 8px;
		float: right;
		[dir="rtl"] & {
			float: left;
		}
		button {
			background-color: #63b54d;
			min-width: 120px;
			color: #ffffff;
			border: none;
			padding: 6px;
			margin: 6px 0;
			border-radius: 4px;
		}
		a {
			background-color: #63b54d;
			width: 120px;
			color: #ffffff;
			border: none;
			padding: 6px;
			margin: 6px 0;
			display: inline-block;
			border-radius: 4px;
			text-align: center;
		}
	}
}
.list-groups {
	background-color: #e9e9e9;
	.button-view {
		background-color: transparent;
		border: none;
	}
}
.text-gray {
	color: #656565;
}
.btn-link {
	font-size: $font14;
	font-weight: 600;
	.fas {
		margin: 0 3px;
	}
	.far {
		margin: 0 3px;
	}
}
.w-10 {
	width: 10%;
}
.w-14 {
	width: 14%;
}
.w-20 {
	width: 20%;
}
.w-55 {
	max-width: 55%;
}
.add-model {
	.title-model {
		padding: 10px;
		font-size: 18px;
		color: #656565;
	}
	.box-title-model {
		background: #f2f2f2;
	}
	.card-group {
		width: 100%;
		border: 1px solid #d6d6d6;
		padding: 0;
		margin: 10px 0;
		.box-title {
			text-align: left;
			[dir="rtl"] & {
				text-align: right;
			}
			padding: 7px;
			width: 100%;
			background-color: #f2f2f2;
			border-bottom: 1px solid #d6d6d6;
			h3 {
				display: inline-block;
			}
		}
		.box-row {
			width: 100%;
			display: block;
			text-align: left;
			padding: 4px 7px;
			[dir="rtl"] & {
				text-align: right;
			}
			h3 {
				display: inline-block;
			}
			label {
				position: relative;
				top: -2px;
				font-size: 14px !important;
			}
		}
	}
}
.btn-add {
	width: 200px;
	background-color: #63b54d;
	color: #ffffff;
	border: 5px;
	font-size: $font16;
}
.btn-cancel {
	width: 120px;
	color: #ffffff;
	border: 5px;
	font-size: $font16;
}
.p-d-15 {
	padding: 15px;
}
.p-d-20 {
	padding: 20px;
}
.lab-info-box {
	background-color: #fff;
	margin-bottom: 15px;
}
.work-days {
	margin-bottom: 8px;
	.day {
		display: inline-block;
		margin: 0 7px;
		label {
			font-size: 16px !important;
		}
	}
	.input-group {
		margin: 0 !important;
	}
}
.text-label {
	text-align: right;
	margin-bottom: 18px;
	padding: 0px 15px;
	[dir="rtl"] & {
		text-align: left;
	}
}
.fix-row {
	margin: 0 !important;
}
.over-flow {
	overflow: hidden;
}
.float-left-local {
	float: right;
	[dir="rtl"] & {
		float: left;
	}
}

.company-header {
	background: #f2f2f2;
	padding: 8px;
	.company-title {
		font-size: 22px !important;
		font-weight: 600;
		color: #656565;
		display: inline-block;
	}
}
