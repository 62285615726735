.login-page {
	display: flex;
	align-items: center;
	height: 70vh;
	background: #f4f4f4;
	.box-login {
		width: 500px;
		height: 500px;
		border-radius: 50%;
		margin: 0 auto;
		text-align: center;
		background: url(../../../assets/images/login/back-login.png) no-repeat
			center;
		background-size: cover;
		.login-form {
			padding: 1rem;
			form {
				.input-group {
					border: 1px solid #555;
					border-radius: 0.5rem;
					-webkit-box-shadow: 0 2px 5px #999;
					box-shadow: 0 2px 5px #999;
				}
				button {
					border-radius: 0.5rem;
					border: none;
					font-weight: bold;
					background-color: $blueColor;
					-webkit-box-shadow: 0 2px 5px #999;
					box-shadow: 0 2px 5px #999;
					padding: 0.1rem;
					span {
						line-height: 3;
					}
				}
			}
		}
	}
}
